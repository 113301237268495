import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavMenu from "../NavMenu";
import MenuHamburguer from "./MenuHamburguer";
import './NavBar.css'
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const { t } = useTranslation()
    const menuItems = [
        { label: t("home"), link: '/' },
        { label: t("about"), link: '/#Sobre' },
        { label: t("method"), link: '/#metodologia' },
        { label: t("projects"), link: '/#Projetos' },
        { label: t("contact"), link: '/#Contato' },
        { label: t("store"), link: 'https://loja.jogajuntoinstituto.org/' },
        { label: t("seletive-process"), link: 'https://processoseletivo.jogajuntoinstituto.org/' },
        // { label: 'Transparência', link: '#' },
    ];

    const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
        <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
            <Link to="/"><img src="/image/Logo_JogaJunto.png" alt="Logo do instituto Joga Junto referênciando ao site"></img></Link>
            <ul className="web-navmenu">

            <li >
                <NavMenu items={menuItems} />
            </li>
            </ul>
            <div className="menu-hamburguer">
                <MenuHamburguer items={menuItems}/>
            </div>
        </nav>
        




    )
}


export default NavBar